ul.color-choice-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
}

li.color-choice-wrap {
  margin: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

button.btn.color-choice {
  color: transparent;
  border: 2px solid #fff;
  border-radius: inherit;
  mix-blend-mode: difference;
  position: relative;
  min-width: 6rem;
  min-height: 2rem;

  &:focus {
    outline-width: 0;
  }

  &:hover,
  &.active {
    border: 2px solid #fff !important;
    color: #fff;
  }

  &.active {
    color: transparent;

    &::before {
      content: '\2713';
      color: #fff;
      font-weight: bold;
      font-size: 1.5rem;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}