html,
body,
div {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 16px;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  vertical-align: baseline;
}

.error-message {
  background-color: rgba(255, 0, 0, .35);
  border: 1px solid rgba(255, 0, 0, 1);
  
  p {
    margin: 0.5rem 0;
    text-align: center;
    font-size: 0.75rem;
  }
}

.loading-message {
  background-color: #edf4fc;
  border: 1px solid #84b9f5;
  
  p {
    margin: 0.5rem 0;
    text-align: center;
    font-size: 0.75rem;
  }
}

pre {
  white-space: pre-wrap; 
  word-wrap: break-word;
  font-size: 0.75rem;
  
  code {
    position: relative;
    width: 100%;
    display: block;
    padding: 1rem;
    margin: 1rem 0;
    font-size: 0.75rem;

    &::after {
      position: absolute;
      height: 100%;
      width: 100%;
      border: solid 1px rgb(128, 128, 128);
      top: 0;
      left: 0;
      content: '';
    }

    &::before {
      position: absolute;
      height: 1.75rem;
      width: 100%;
      top: 0;
      left: 0;
      padding: 0.25rem;
      content: ' Code';
      font-size: 1rem;
      font-family: monospace;
      text-transform: uppercase;
      background-color: rgb(238, 238, 238);
    }
  }
 }

img {
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  max-width: 300px;
}

del {
  position: relative;
  color: rgba(255, 0, 0, .5);
  text-decoration-color: rgba(255, 0, 0, 1);
  display: inline-block;

  &::before {
    background-color: rgba(255, 0, 0, .35);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }
}

ins {
  text-decoration: none;
  border-bottom: solid 2px green;
  position: relative;
  display: inline-block;

  &::before {
    background-color: rgba(0, 255, 0, .35);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  .embedded-asset-block & {
    border-bottom: none;

    &::before {
      content: none;
    }
  }

  .entry-name & {
    border-bottom: none;
  } 

  [data-field-type=Link] & {
    display: inline-block;
  }
}

label {
  font-size: 0.875rem !important;
  display: block;
  color: #606c7c;
  margin-bottom: 0.25rem;
}

.field-list-wrap {
  list-style: none;
  padding-left: 0;
  width: 100%;

  > .diff-field-wrap {
    padding: 0.5rem 0.5rem;
    margin: 1rem 1rem;
    border-left: solid 2px rgb(211, 220, 224);
  }

  .embedded-inline-entry {
    .diff-field-wrap {
      padding: 0;
    }
  }
}

.array-field-wrap {
  list-style: none;
  display: flex;
  padding-left: 0;

  li {
    border: solid 1px rgba(128, 128, 128, 0.75);
    border-radius: .25rem;
    background-color: white;
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    font-size: 0.75rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
}


.diff-text {
  width: 100%;
  flex-basis: 0;
  flex-grow: 1;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.875rem;

  .diff-field-line-wrap & {
    padding-left: 0;
    padding-right: 0;
  }

  &_snapshot,
  &_current {
    display: none;
  }

  &:last-of-type {
    border-bottom: none;
    padding-right: 0;
  }

  &:first-of-type {
    padding-left: 0;
  }

  p {
    line-height: 1.25em;
    display: inline;
    font-size: 0.875rem;
    
    + p {
      display: block;
      margin-bottom: 0.25rem;
      margin-top: 0.5rem;
    }
  }

  .diff-level-max {
    padding-top: 0;
    padding-bottom: 0;

    p, ins, del {
      color: rgba(255, 0, 0, .5);
      font-size: 0.75rem;
      font-style: italic;
      margin-bottom: 0;
    }
  }

  .embedded-entry-block,
  .embedded-inline-entry,
  .embedded-asset-block {
    display: block;
    border: solid 1px rgb(211, 220, 224);
    margin: 1.75rem 0;
    background-color: white;

    > * {
      background-color: white;
      padding: 0.875rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .embedded-entry-block {
    .field-list-wrap {
      padding: 0;
    }
  }

  .entry-name {
      position: relative;
      width: 100%;
      display: block;
      padding: 0.25rem;
      margin: 1rem 0 0 0;
      font-size: 0.75rem;
      border: solid 1px rgb(238, 238, 238);
      background-color: rgb(238, 238, 238);
      text-transform: uppercase;
  }
}

.diff-field-line-wrap {
  display: flex;

  &[data-field-type=RichText] {
    background: #edf4fc;
    padding: 1.25rem;
  }

  &[data-field-type=Link] {
    del {
      margin: 0.5rem 3rem 0.5rem 0;

      &::before {
        background-color: rgba(255, 0, 0, 0.75);
      }
      
      img {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  .entry-name {
    del {
      margin: 0;

      &::before {
        background-color: rgba(255, 0, 0, 0.25);
      }
    }
  }
}