#add-table-row-wrap {
    margin-top: 1rem;
}

table.steps-table {
    margin-top: 1rem;

    tr {
        td {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            vertical-align: middle;

            &.col-actions {
                width: 1%;
                white-space: nowrap;
                
                * {
                    visibility: hidden;
                }
            }
        }

        &:hover {
            td.col-actions {
                * {
                    visibility: visible;
                }
            }
        }
    }

    th {
        font-weight: bold;
    }
}

#dialog-step-wrap {
  margin: 2rem;
}

#form-actions-row {
    * {
        text-align: center;
    }
}

#bulk-editing{
    margin: 1rem;
    height: 500px;
    width: 100%;
}

#bulk-editing th {
    position: sticky;
    top: 0;
    z-index: 1000;
}

#bulk-editing p {
    margin: 1rem auto;
    width: 800px;
    text-align: center;
}

#bulk-editing .action-buttons {
    padding: 1rem 2rem;
    position: fixed;
    bottom: 0;
    text-align: right;
    width: 100%;
    background-color: white;
}

#bulk-editing .action-buttons .bulk-submit-btn {
    margin-left: 2rem;

}

#bulk-editing .action-buttons .bulk-cancel-btn {

}

.bulk-step {
    width: 50px;
}